import { classList } from "./scavengerList"

class BingoCard {
    constructor(cardType) {
        this.cardType = "scavenger";
        this.bingoCard = [];
        this.classList = classList;
    }

    buildBingoCard() {

        this.classList.forEach(item => { item.timesUsed = 0 });

        for (let square = 0; square < 50; square++) {
            let classUseCap = 1;

            let foundSquare = this.findClass(classUseCap, "scavenger");
            this.bingoCard.push({
                squareID: square,
                instructor: foundSquare.instructor,
                // classType: foundSquare.classType,
                planDate: null,
                complete: false
            });
        }

       

        return this.bingoCard;
    }

    findClass(classUseCap, cardType) {

        let classFound = false;
        let randomNum = 0;

        while (!classFound) {
            randomNum = Math.floor(Math.random() * this.classList.length);

            if (this.classList[randomNum].classType === cardType &&
                this.classList[randomNum].timesUsed < classUseCap) {
                this.classList[randomNum].timesUsed++;
                classFound = true;
            }
        }

        return this.classList[randomNum];
    }

}

export default BingoCard