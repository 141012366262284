export const classList = [
    { instructor: "Instructor is wearing animal print", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor is wearing camo", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor is wearing mesh/lace", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor is wearing red, white, and blue", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor is wearing blinged-out shoes", classType: "scavenger", timesUsed: 0 }, 
    { instructor: "Instructor is wearing brightly-colored shoes (non-traditional color)", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor isn’t wearing any shoes", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor is wearing tye-dye", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor is wearing an item of clothing with words on it that arent 'Peloton'", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instrucor is wearing something with a rainbow on it", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor is wearing floral print", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor wearing shiny pants", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor is wearing the same color top and bottom", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor is wearing long sleeves", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor is wearing a fall color (red, orange, gold, dark green, brown)", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor is wearing your favorite color", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor is wearing all black", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor is wearing a costume", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor with a birthday in October", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor with no hair", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor with pig tails", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor with hair down", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor with dyed hair/wig (e.g. pink, blue, etc.)", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor is wearing a head band", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor is wearing a red hat", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor is wearing a white hat", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor has tattoos", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor has a pet(s)", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor is a native New Yorker", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor came to New York from somewhere else", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor with an accent", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor is bi-lingual", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor lives outside the US", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor wrote a book", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor got married or had a baby in the last 18 months", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with an instructor who was one of the first 10 Peloton instructors", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with an instructor who was one of the 10 newest Peloton instructors", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with the oldest Peloton instructor", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with the youngest Peloton instructor", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class where Robin is wearing yellow", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class where music is from a movie or play", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class where the instructor has a first or last initial that matches your first or last initial", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class in a different language", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class you can take sitting down", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class you can take standing only", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class you can take laying down", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that requires equipment or props other than weights", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that doesn’t require any equipment", classType: "scavenger", timesUsed: 0 }, 
    { instructor: "Class you might find with other similar classes", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that might not really be considered a class", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that gets you ready for a workout", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that you take at the end of a workout", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that targets the core", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that targets the biceps", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that targets the triceps", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that targets the shoulders", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that targets the glutes", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that targets the quads", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that targets the hamstrings", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that targets the chest", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that targets the back", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that targets the feet", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that targets the neck", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that targets the wrists", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class recorded on your birthday", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with your favorite genre of music", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class recorded outside of main Peloton studios", classType: "scavenger", timesUsed: 0 },
    { instructor: "Artist series class of someone you are not familiar with", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class on a hill", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with more than one instructor/guest", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with members in the studio", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with no members in the studio", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with your favorite instructor", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with an instructor you've never tried or rarely take", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that is highly intense", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that is low impact", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class you can take outside", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that is SO extra", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that is happening right now", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class for runners that isn't running", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class for riders that isn't riding", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class you might take to let off a little steam", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that incorporates cardio and strength", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class you might take when you are feeling a certain way", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class in an instructor's signature series", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that is great for someone new to Peloton", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that celebrates LHHM", classType: "scavenger", timesUsed: 0 },
    { instructor: "Halloween themed class", classType: "scavenger", timesUsed: 0 },
    // { instructor: "Class with song 'Wake Me Up When September Ends' on the playlist", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with Usher on the playlist (Oct birthday)", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with Tom Petty (October birthday)", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with No Doubt on the playlist (band member has Oct birthday)", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with Red Hot Chili Peppers on the playlist (band member has Oct birthday)", classType: "scavenger", timesUsed: 0 },
    { instructor: "Collect a badge from the activity challenge", classType: "scavenger", timesUsed: 0 },
    { instructor: "Collect one badge from one of the challenges - ride/row/run", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class from the Artist Series collection", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class from the Body Appreciation collection", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class from the Century collection", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class from the Mental Health collection", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor is wearing a hat backwards", classType: "scavenger", timesUsed: 0 },
]
